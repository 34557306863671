export default [
  {
    subtitle: '“Any company can ship products but Heroshe made sure my items were complete and well packaged. None of my products were broken. You can tell by how their customer care representatives talk, that they put their customers first”',
    clientAvatar: '/svg/testimonials/client2.svg',
    fullName: 'Toni Akinsola'
  },
  {
    subtitle: '“Good service. They have good tracking information, so you know where your package is every step of the way. Was so happy to hear my folks call me that they got the package with no hassles(no customs/post office wahala)” ',
    clientAvatar: '/svg/testimonials/client5.svg',
    fullName: 'Ayodeji Bolaji'
  },
  {
    subtitle: '"I recommend Heroshe to all my friends who need shipping services. Their customer service is on point, and even when there are delays (not often) they give a heads up before you even think to reach out to them. And I love the little surprises they put in their delivery boxes ;)"',
    clientAvatar: '/svg/testimonials/client46.svg',
    fullName: 'Mohini Ufeli'
  },
  {
    subtitle: '"In over 1½ year of Shipping with Heroshe - From Houston, Texas to Osun State, Nigeria - All items are always well RePackaged and Received. Prompt and sometimes, a little delay in Nigeria as we all know how things can be when getting shipments into the country...even despite the Pandemic last year, they did 5* Rating as for me."',
    clientAvatar: '/svg/testimonials/client16.svg',
    fullName: 'Alo Oluwasegun Adeyemi'
  },
  {
    subtitle: '“There is no better deal to ship stuff from USA to Nigeria. Go ahead, I dare you.\n\nHeroshe are simply the best. Items in Nigeria are priced at double what it takes for me to buy and ship to my house.”',
    clientAvatar: '/svg/testimonials/client9.svg',
    fullName: 'Nnamdi Wakwe'
  },
  {
    subtitle: '"I am really impressed with the speed and professionalism of the Heroshe team. The deliveries are on time and shipping costs are comparative. They respond to queries and questions quickly and given my experiences with other shipping firms, I must say you guys deserve STARS ✨ ✨ ✨"',
    clientAvatar: '/svg/testimonials/client48.svg',
    fullName: 'Ese Norman'
  },
  {
    subtitle: '"Heroshe handled my shipment in a very professional manner. I discovered the company after much search for a reliable freight forwarder. Excellent customer service and speedy delivery (I got my first package four days after it shipped from US, Saturday and Sunday inclusive!). I will do business with Heroshe again and again."',
    clientAvatar: '/svg/testimonials/client41.svg',
    fullName: 'Sam Adikwu'
  },
  {
    subtitle: '"What I particularly enjoy is the personalised service throughout the entire process, always just an email or quick phone call away. Everyone I have had the pleasure of dealing with at Heroshe leaves me with a warm feeling, confident that my packages are in good hands. True Heroes! Thanks guys"',
    clientAvatar: '/svg/testimonials/client33.svg',
    fullName: 'Ekan Emokpae'
  },
  {
    subtitle: '"Best shipping service I have used so far Once my items arrived at their address in the US, I was promptly notified and after I paid the agreed Charge, in about two weeks time, they were at my door with the items, nothing damaged, nothing missing. Kudos"',
    clientAvatar: '/svg/testimonials/client24.svg',
    fullName: 'Ama Ama'
  },
  {
    subtitle: '"Heroshe is an effective, efficient and benevolent shipper. Lovely chocolate and biscuits included in every chocolate and biscuit they ship. Good value for money and great customer service when called upon. Shipments rarely have any issues and my concerns are  very well taken care of.  Having heroshe take care of my shipping needs, has given me great ease"',
    clientAvatar: '/svg/testimonials/client1.svg',
    fullName: 'Ufuoma Odije'
  },
  {
    subtitle: '“Where do i start from? seriously wish I could give more than five stars. I was skeptical when I first saw their ad. I had been burnt many times by different shippers. So I called and their customer service was really awesome. I was still skeptical because anyone could have sounded really adorable and nice on the phone, so I tried them out. Yes i was blown away!”',
    clientAvatar: '/svg/testimonials/client3.svg',
    fullName: 'Damilola Onakoya'
  },
  {
    subtitle: '“Not missed a pin since I started. Quite reliable. Delivery is now prompt and that is very encouraging. I recommend them”',
    clientAvatar: '/svg/testimonials/client6.svg',
    fullName: 'Okey Onuzo'
  },
  {
    subtitle: '“The delivery timeline might be a little long but it is always worth the wait as I have never had an issue with a package. I have also never had an issue with payments which is something I fight about with other companies. The service is next to none, from the website design to the email notification updates to the predictability of the delivery timeline”',
    clientAvatar: '/svg/testimonials/client7.svg',
    fullName: 'Raymond Tukpe'
  },
  {
    subtitle: '“I love this company, their processes makes you feel like you are not in Nigeria, very detailed in their operation, your items won’t have any issues and you’ll get them within the stipulated time.Thanks Heroshe! You guys rock”',
    clientAvatar: '/svg/testimonials/client11.svg',
    fullName: 'Olowojoba Rebecca'
  },
  {
    subtitle: '“I enjoyed the efforts heroshe puts in making sure their customers(me) gets their packages.. Thanks heroshe will definitely use again”',
    clientAvatar: '/svg/testimonials/client12.svg',
    fullName: 'Alimi Oluwabukola'
  },
  {
    subtitle: '"My goods lshipped to ware house on August 26th,2021. Received by Heroshe on August 31st,2021. Packed and  Shipped to Lagos September 02, 2021. Item delivered and Received in Lagos September 15th, 2021.Quick and speed delivery"',
    clientAvatar: '/svg/testimonials/client13.svg',
    fullName: 'Leena Bright'
  },
  {
    subtitle: '"Got my device delivered without hassles, you’re the best @Heroshe Will recommend to my friends and do more shipping through you..."',
    clientAvatar: '/svg/testimonials/client14.svg',
    fullName: 'Joseph Ojoko (Tewshi)'
  },
  {
    subtitle: '"The service was seamless. This is my new go-to partner for shipments from the US. Thank you Heroshe"',
    clientAvatar: '/svg/testimonials/client15.svg',
    fullName: 'Adedoyin Adeola'
  },
  {
    subtitle: '"Started using heroshe late last year and so far so good. However delivery timelines can be improved upon as most times there are cases of items not arriving together even when they are shipped together. Would not be bad if we can get …"',
    clientAvatar: '/svg/testimonials/client17.svg',
    fullName: 'Tuntun AyaTosin'
  },
  {
    subtitle: '"Heroshe is a breath of fresh air. At first i thought they were pricier than other Shipping Companies  i had used, but then i realised they have no hidden costs, they are extremely detailed and you can track the progress of your Shipment.Their Staff are extremely polite & helpful, especially a lady called Subomi.Fantastic experience"',
    clientAvatar: '/svg/testimonials/client18.svg',
    fullName: 'Oluyemisi Akere'
  },
  {
    subtitle: '"This is probably my first review of any business on Google. If you are looking to have your shopped items from abroad delivered to you in Nigeria (from the US), then Heroshe is a no-brainer! You pay less shipping fee & your items get delivered in about two weeks. Crazy!!!"',
    clientAvatar: '/svg/testimonials/client20.svg',
    fullName: 'Lukman Olushi'
  },
  {
    subtitle: '"Well organised and structure environment and quality service, They handle your item with utmost care and consider all security measure in place for the safety of items. Staff are professional and give warm welcome, the environment is well lit and serenade."',
    clientAvatar: '/svg/testimonials/client21.svg',
    fullName: 'Adeniyi Temidayo'
  },
  {
    subtitle: '"Recommended by my friend, shipping from the US has never been easier, thanks to the team for providing great service and always open to improvements. The added chocolate is a delight, and shows how thoughtful your organization is. Thank you."',
    clientAvatar: '/svg/testimonials/client22.svg',
    fullName: 'Mary Job'
  },
  {
    subtitle: '"Heroshe has been my go to logistics service for about a year now, I haven\'t had any regrets. Top notch services always. Very pocket friendly!"',
    clientAvatar: '/svg/testimonials/client23.svg',
    fullName: 'Akinlowo Olawoye'
  },
  {
    subtitle: '"My items arrived earlier than expected. Everything intact. The fees are fair and I\'ll definitely use the service again. 10 outta 10, would definitely recommend."',
    clientAvatar: '/svg/testimonials/client25.svg',
    fullName: 'Daniel Majin'
  },
  {
    subtitle: '"This is the best shipping company in Nigeria in my opinion. They are highly reliable, responsive and efficient. I got my package on time and in good condition. Thank you so much heroshe for the pleasant experience. Satisfied customer!"',
    clientAvatar: '/svg/testimonials/client26.svg',
    fullName: 'Blessing E'
  },
  {
    subtitle: '"Heroshe is the best, honest and reliable shipping company ever. I love your services so much."',
    clientAvatar: '/svg/testimonials/client29.svg',
    fullName: 'Otunba Worldwide'
  },
  {
    subtitle: '"I have just received my first ever order through heroshe. The whole process was seamless. I got regular updates. They were always available to answer my questions. They delivered way ahead of schedule and my items all came intact. There was a gift of chocolate in each of my boxes. Thank you so much for an awesome service. I will recommend to everyone I know."',
    clientAvatar: '/svg/testimonials/client30.svg',
    fullName: 'Aisha Oyawoye'
  },
  {
    subtitle: '"No hassles , they are the best honestly, you needn’t worry at all when you’ve got your package with them but Heroshe please airports in Nigeria are opened and working , can we go back to the 10 days delivery , please 😩😩.."',
    clientAvatar: '/svg/testimonials/client31.svg',
    fullName: 'Tunde Gbadebo'
  },
  {
    subtitle: '"Your services are swift with so much excellence. I have not had any issues since I started using Heroshe. Customer service is top notch.. Keep up the good work! \n\nP.S I like the chocolates you always add to my packages. Thank you"',
    clientAvatar: '/svg/testimonials/client32.svg',
    fullName: 'Sophia Abrakasa'
  },
  {
    subtitle: '"So I was using a different know US shipping company to Nigeria and one day I happened upon Heroshe. I tried them out in this pandemic and am so glad I found you guys.... Paid just my shipping fees. No duty nothing else.... Am sure to recommend you guys.... Thank you for allowing me find you 💕💕💕"',
    clientAvatar: '/svg/testimonials/client34.svg',
    fullName: 'Happiness Aaron'
  },
  {
    subtitle: '"Very efficient and professional service, looking forward to using your service again.. Highly recommend."',
    clientAvatar: '/svg/testimonials/client35.svg',
    fullName: 'Fitzgerald Goroye'
  },
  {
    subtitle: '"My item came-in good and timely too. I did saw a gift receipt(paper) but no physical gift item present. Good doing business with you. Hoping to doing more. Thanks"',
    clientAvatar: '/svg/testimonials/client36.svg',
    fullName: 'Mentusu World'
  },
  {
    subtitle: '"Heroshe shipping is very reliable. Their charges are not exorbitant.. They maintain very good communication with customer. The general feeling I have is that Heroshe is managed by a godly person."',
    clientAvatar: '/svg/testimonials/client39.svg',
    fullName: 'Paul Nosike'
  },
  {
    subtitle: '"When I heard about Heroshe, I was honestly skeptical about the service because the whole thing seemed too good to be true so I decided to try them out with a small item. The whole experience was honestly wonderful. Top notch customer service and delivery service. The only thing they care about is putting their customers first. I totally recommend!"',
    clientAvatar: '/svg/testimonials/client42.svg',
    fullName: 'Agunbuade Victor'
  },
  {
    subtitle: '"A1 experience to say the least. They said you\'d be amazed by the speed of tgeir services and they meant it. Also, they always communicate well with their customers and they sent a complimentary bar of chocolate in the shipment box so...5 stars already. Will use again and definitely recommend :)"',
    clientAvatar: '/svg/testimonials/client43.svg',
    fullName: 'Ebuka xo'
  },
  {
    subtitle: '"Excellent customer service with prompt response to my enquries.  My shipment was also delivered on time and  in good condition with chocolates for an extra touch.  I would definitely use Heroshe services again."',
    clientAvatar: '/svg/testimonials/client44.svg',
    fullName: 'Seun Ayeni'
  },
  {
    subtitle: '"I Started using Courier services since 2017, but since i started using Your services, there have been absolutely no comparison. you guys are the best ever, please keep it up. \nplease i prefer the formal chocolate than the kitkat."',
    clientAvatar: '/svg/testimonials/client45.svg',
    fullName: 'Uyi Gideon'
  },
  {
    subtitle: '"They are awesome, no hassle, reliable and proactive with updates and the customer delight team is fantastic! Love and will recommend them."',
    clientAvatar: '/svg/testimonials/client47.svg',
    fullName: 'Lamide Young'
  },
];
