
import StelaPageLayout from '~/components/layout/StelaPageLayout';
import StelaHelpCenterPageHeaderPanel from '~/components/panels/StelaHelpCenterPageHeaderPanel';
import StelaContactInfoPanel from '~/components/panels/StelaContactInfoPanel';

export default {
  components: {
    StelaPageLayout,
    StelaHelpCenterPageHeaderPanel,
    StelaContactInfoPanel
  },
  props: {
    showMainMenu: {
      type: Boolean,
      default: false,
    },
    showHeaderPanel: {
      type: Boolean,
      default: true,
    },
    heroBackgroundUrl: {
      type: String,
      default: '/img/help-center-banner-header.png',
    },
    heroButtons: {
      type: Array,
      default: () => [],
    },
    alignHeadline: {
      type: String,
      default: 'left',
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    showRecommendedReadingPanel: {
      type: Boolean,
      default: true,
    },
    showHelpfulVideosSection: {
      type: Boolean,
      default: false,
    },
    showContactInfoPanel: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      defaultHeroBackgroundUrl: '/img/help-center-banner-header.png',
    };
  },
  computed: {
    activeSlug () {
      const { slug } = this.$route.params;
      return slug || '';
    },
    categorySlug () {
      return this.activeSlug;
    },
  }
};
