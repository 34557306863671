
export default {
  name: 'TestimonialCard',
  props: {
    rating: {
      type: Boolean,
      default: true,
    },
    isAvatar: {
      type: Boolean,
      default: true,
    },
    details: {
      type: String,
      default: null,
    },
  }
};
