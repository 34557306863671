
export default {
  name: 'StelaHelpCenterPageHeaderPanel',
  props: {
    backgroundUrl: {
      type: String,
      default: '/img/help-center-banner-header.png'
    },
  },
};
