
import { mapGetters } from 'vuex';
import TestimonialsLayout from '~/components/testimonials/TestimonialsLayout';
import TestimonialsCard from '~/components/testimonials/TestimonialsCard';
import testimonials from '~/assets/data/testimonials.js';

export default {
  name: 'Testimonials',
  components: {
    TestimonialsLayout,
    TestimonialsCard,
  },
  data () {
    return {
      initialReviews: 9,
      showReviews: true,
    };
  },
  head () {
    return {
      title: this.pageHead?.title || 'Customer Testimonials - Heroshe',
      link: this.pageHead?.link || [],
      meta: this.pageHead?.meta || [
        {
          hid: 'description',
          name: 'description',
          content:
            '40,000+ people have trusted us with their shipping. Here’s what more than 100 of them have to say about Heroshe.',
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: 'Heroshe - Customer Testimonials',
        },
        {
          hid: 'apple-mobile-web-app-title',
          name: 'apple-mobile-web-app-title',
          content: 'Heroshe - Customer Testimonials',
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: 'Heroshe - Customer Testimonials',
        },
      ],
      script: this.pageHead?.script
        ? this.pageHead?.script?.map(this.mapHeadScript)
        : [],
      __dangerouslyDisableSanitizers: ['script'],
    };
  },
  computed: {
    defaultHero () {
      return {
        title: 'Customer Testimonials',
        description:
          '40,000+ people have trusted us with their shipping. Here’s what more than 100 of them have to say about Heroshe.',
        description2: `<span>See why we are rated 4.7/5 on </span><a href="${this.CUSTOMER_REVIEW_URL}" target="_blank" class="underline">Google</a>`,
      };
    },
    pageKey () {
      return 'testimonials-page';
    },
    pageData () {
      return this.allPageData[this.pageKey] || {};
    },
    clientTestimonials () {
      return testimonials.slice(0, this.initialReviews);
    },
    pageHead () {
      return this.pageData?.head || {};
    },
    hero () {
      return this.pageData?.hero || this.defaultHero;
    },
    heroBackgroundImageUrl () {
      return (
        this.getBackgroundImageUrl(this.hero) || this.hero?.backgroundImageUrl
      );
    },
    heroButtons () {
      return this.hero?.buttons || [];
    },
    gettingStartedSectionOne () {
      return this.pageData?.gettingStartedSectionOne || {};
    },
    gettingStartedSectionTwo () {
      return this.pageData?.gettingStartedSectionTwo || {};
    },
    gettingStartedSectionThree () {
      return this.pageData?.gettingStartedSectionThree || {};
    },
    ...mapGetters({
      allPageData: 'page/pageData',
    }),
  },
  methods: {
    displayReviews () {
      this.initialReviews = testimonials.length;
      this.showReviews = false;
    },
  },
};
