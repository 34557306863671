
export default {
  name: 'StelaContactInfoPanel',
  props: {
    title: {
      type: String,
      default: 'Still got questions?',
    }
  },
};
